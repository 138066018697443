<template>
  <div class="col-12 col-lg-6 c g">
    <br><br>
    <h2 class="text-center">
            اشتراكك في <strong class="text-primary">{{ subscribeinfo(code).name }}</strong>
    </h2>
    <h4 class="text-danger text-center g">
            هناك زيادة في العدد المسموح به <span>{{ current.limit }} والحالي هو {{ current.current }}</span>
    </h4>
    <div class="card card-body">
        <div class="col-12">
            <div v-if="r.request_students_count" class="col-12 col-lg-6 c">
                <div class="form-group">
                  <span for="">عدد الطلاب المراد زيادتهم</span>
                  <input type="number"
                    class="form-control" v-model="students" @change="getHTML()">
                </div>
                <div class="col-12 text-center">
                    <button class="btn btn-sm btn-primary"><i class="fa fa-dollar"></i> احتساب التكلفة</button>
                    &nbsp;
                    <button class="btn btn-sm btn-warning" @click="$router.push('/students')"><i class="fa fa-users"></i> التحكم في الطلاب</button>
                </div>
            </div>
            <div v-if="r.request_teachers_count" class="col-12 col-lg-6 c">
                <div class="form-group">
                  <span for="">عدد المعلمين المراد زيادتهم</span>
                  <input type="number"
                    class="form-control" v-model="teachers" @change="getHTML()">
                </div>
                <div class="col-12 text-center">
                    <button class="btn btn-sm btn-primary"><i class="fa fa-dollar"></i> احتساب التكلفة</button>
                    &nbsp;
                    <button class="btn btn-sm btn-warning" @click="$router.push('/teachers')"><i class="fa fa-users"></i> التحكم في المعلمين</button>
                </div>
            </div>
            <div id="result" style="padding: 10px; margin: 0px auto;" v-html="html"></div>
            <div class="col-12 text-center">
                <div class="col-12 g col-lg-6 c" v-if="plan">
                    <p>
                        دفع عن طريق:
                    </p>
                    <button class="btn btn-success btn-block" v-if="pay_online" @click="manual = false; buynow()">
                        <i class="fa fa-cc-visa"></i>
                        دفع بالفيزا (بنك الراجحي)
                    </button> 
                    <button class="btn btn-primary btn-block" v-if="pay_online" @click="manual = false; buynow()">
                        <i class="fa fa-apple"></i>
                        دفع بـ Apple Pay
                    </button> 
                    <button class="btn btn-secondary btn-block" @click="manual = true;" v-b-modal.manual>
                        <i class="fa fa-bank"></i>
                        تحويل بنكي
                    </button>
                </div>
                <br>
                <a href="/"><i class="fa fa-reply"></i> عودة للرئيسية</a>
            </div>
        </div>
    </div>
    <b-modal id="manual" title="تحويل بنكي" hide-footer>
        <code style="font-size: 16px" v-html="manual_info">
        </code>
        <hr>
        بعد التحويل قم بملئ الحقول التالية:
        <br><br>
        <div class="form-group">
            <span for="">ايصال التحويل</span>
            <input type="file"
            class="form-control" id="ingredient_file" @change="uploadImage()" accept=".jpg,.png,.gif,.jpeg,.pdf">
        </div>
        <div id="progress-wrp">
            <div class="progress-bar"></div>
            <div class="status">0%</div>
        </div>
        <div class="form-group">
          <span for="">ملاحظات (اختياري)</span>
          <textarea class="form-control" v-model="manual_notes" rows="3" placeholder="اكتب اي ملاحظات... (اختياري)"></textarea>
        </div>
        <div class="col-12 text-center g">
            <button class="btn btn-success" @click="buynow()">
                ارسال <i class="fa fa-arrow-left"></i>
            </button>
        </div>
    </b-modal>
  </div>
</template>

<script>
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import { VBModal, BModal, BFormInput, BDropdown, BDropdownItem } from "bootstrap-vue";
export default {
    components: {
        // Layouts
        BDropdown, BDropdownItem,
        vSelect,
        BModal,BFormInput},
    directives: {
        Ripple,
        "b-modal": VBModal,
    },
    data(){
        return {
            subscribes: [],
            code: window.location.href.split("=")[1],
            plan: false,
            html: "جاري التحميل...",
            user: JSON.parse(localStorage.getItem("user")),
            r: {},
            students: 0,
            current: {},
            teachers: 0,
            manual: false,
            done: false,
            manual_file: "",
            manual_notes: "",
            services: [],
            manual_info: 'جاري التحميل...',
            pay_online: false
        }
    },
    created(){
        if(!checkPer("subscribe")){
            this.$router.push('/NotPermitted')
            return false;
        }
        var g = this;
        var user = localStorage.getItem("user");
        if(user){
        $.post(api + '/general/services', {
            jwt: this.user.jwt
        }).then(function(r){
            g.loading = false
            if(r.status != 100){
                alert(r.response)
            }else{
                g.services = r.response
                setTimeout(() => {
                    g.getHTML()
                }, 500);
            }
        }).fail(function(){
            g.loading = false
        })
        }
    },
    methods: {
        uploadImage(){
            var g = this;
            var Upload = function (file) {
                this.file = file;
            };

            Upload.prototype.getType = function () {
                return this.file.type;
            };
            Upload.prototype.getSize = function () {
                return this.file.size;
            };
            Upload.prototype.getName = function () {
                return this.file.name;
            };
            Upload.prototype.progressHandling = function (event) {
                var percent = 0;
                var position = event.loaded || event.position;
                var total = event.total;
                var progress_bar_id = "#progress-wrp";
                if (event.lengthComputable) {
                percent = Math.ceil((position / total) * 100);
                }
                // update progressbars classes so it fits your code
                $(progress_bar_id + " .progress-bar").css("width", +percent + "%");
                $(progress_bar_id + " .status").text(percent + "%");
            };
            $("#progress-wrp").fadeIn("slow");
            Upload.prototype.doUpload = function () {
                var that = this;
                var formData = new FormData();

                // add assoc key values, this will be posts values
                formData.append("file", this.file, this.getName());
                formData.append("jwt", g.user.jwt);

                $.ajax({
                type: "POST",
                url: api + '/user/general/upload',
                xhr: function () {
                    var myXhr = $.ajaxSettings.xhr();
                    if (myXhr.upload) {
                    myXhr.upload.addEventListener(
                        "progress",
                        that.progressHandling,
                        false
                    );
                    }
                    return myXhr;
                },
                success: function (data) {
                    $("#progress-wrp").fadeOut("slow");
                    // your callback here
                    if(data.status != 100){
                        alert("هذه الصورة غير مدعومة", 200)
                        return;
                    }
                    g.manual_file = data.response
                },
                error: function (error) {
                    // handle error
                    alert("حدث خطأ");
                },
                async: true,
                data: formData,
                cache: false,
                contentType: false,
                processData: false,
                timeout: 60000,
                });
            };
            var file = $("#ingredient_file")[0].files[0];
            var upload = new Upload(file);
            upload.doUpload();
        },
        getHTML(){
            var g = this;
            g.done = true;
            $.post(api + '/user/subscribe/subscribe-update-count-html', {
                jwt: this.user.jwt,
                service: this.code,
                plan: this.plan,
                students: this.students,
                teachers: this.teachers
            }).then(function(r){
                g.current = r.response.current
                g.loading = false
                g.html = r.response.html
                g.r = r.response
                if(g.students == 0){
                    g.students = g.r.students
                }
                if(g.teachers == 0){
                    g.teachers = g.r.teachers
                }
                g.manual_info = r.response.reseller.manual_info;
                g.pay_online = r.response.reseller.online_pay;
            }).fail(function(){
                g.loading = false
            })
        },
        subscribeinfo(code){
        var g = this, r = {};
        g.services.forEach(function(a){
            if(a.code == code){
            r = {
                name: a.title,
                plans: a.plans,
                price: a.price
            }
            if(!a?.plans?.length && !g.done){
                g.getHTML()
            }
            if(!g.plan && a?.plans?.length){
                g.plan = a?.plans[0]?.code
                    if(!g.done){
                        g.getHTML()
                    }
                }
            }
        })
        return r;
        },
        buynow(){
            var g = this;
            alert("جاري التحميل...")
            $.post(api + '/user/subscribe/create-order', {
                jwt: this.user.jwt,
                service: this.code,
                plan: this.plan,
                students: this.students,
                teachers: this.teachers,
                manual: this.manual,
                manual_file: this.manual_file,
                manual_notes: this.manual_notes,
                type: "update"
            }).then(function(r){
                if(r.status == 100){
                    eval(r.response)
                }else{
                    alert("حدث خطأ, " + r.response, 200)
                }
            }).fail(function(){
                alert("حدث خطأ في الاتصال", 200)
            })
        }
    }
}
</script>

<style scoped>
#progress-wrp {
  border: 1px solid #0099cc;
  padding: 1px;
  position: relative;
  height: 30px;
  border-radius: 3px;
  margin: 10px;
  text-align: left;
  background: #fff;
  box-shadow: inset 1px 3px 6px rgba(0, 0, 0, 0.12);
  display: none;
}

#progress-wrp .progress-bar {
  height: 100%;
  border-radius: 3px;
  background-color: greenyellow;
  width: 0;
  box-shadow: inset 1px 1px 10px rgba(0, 0, 0, 0.11);
}

#progress-wrp .status {
  top: 3px;
  left: 50%;
  position: absolute;
  display: inline-block;
  color: #000000;
}
</style>